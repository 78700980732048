import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger'
import reducers from '../reducers';

export default function configureStore() {
  return createStore(
    combineReducers({
      ...reducers
    }),
      applyMiddleware(thunk, createLogger({predicate: (getState, action) => process.env.REACT_APP_NODE_ENV === "development"})),
  );
}
