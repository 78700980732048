import './polyfills'
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

import {HashRouter} from 'react-router-dom';
import './assets/base.scss';
import Main from './App/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

const store = configureStore();
const rootElement = document.getElementById('root');

let instance = null;

if(process.env.REACT_APP_NODE_ENV === 'production'){
    Sentry.init({
        dsn: "https://74d007e0ae15479e95b59a40bc744aff@o1092126.ingest.sentry.io/6542196",
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    instance = createInstance({
        urlBase: 'https://matomo.desainfo.net',
        siteId: 1,
        trackerUrl: 'https://matomo.desainfo.net/matomo.php', // optional, default value: `${urlBase}matomo.php`
        srcUrl: 'https://matomo.desainfo.net/matomo.js', // optional, default value: `${urlBase}matomo.js`
        disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
        heartBeat: { // optional, enabled by default
            active: true, // optional, default value: true
            seconds: 10 // optional, default value: `15
        },
        linkTracking: true, // optional, default value: true
        configurations: { // optional, default value: {}
            // any valid matomo configuration, all below are optional
            disableCookies: true,
            setSecureCookie: true,
            setRequestMethod: 'POST'
        }
    })
}

const renderApp = Component => {
    ReactDOM.render(
        <MatomoProvider value={instance}>
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>
        </MatomoProvider>,
        rootElement
    );
};

renderApp(Main);


if (module.hot) {
    module.hot.accept('./App/Main', () => {
        const NextApp = require('./App/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

