import { Route, Redirect, Switch } from "react-router-dom";
import React, { Suspense, lazy } from "react";
import Loader from "react-loaders";
import useLocalStorage from "../../../hooks/useLocalStorage";
import validator from "validator";
import { ToastContainer } from "react-toastify";
const UserPages = lazy(() => import("../../Pages"));
const routes = lazy(() => import("../../Routes/routes"));
const EmailResetPassword = lazy(() => import("../../Pages/EmailResetPassword"));
const ResetPassword = lazy(()=>import('../../Pages/ResetPassword'))
const AppMain = () => {
  const [login] = useLocalStorage("login", null);
  const [refresh] = useLocalStorage("refresh", null);

  const isAuthenticated = () => {
    if (login() && refresh()) {
      return validator.isJWT(login()) && validator.isJWT(refresh());
    }
  };

  function PrivateRoute({ children, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated() ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/pages/login-boxed",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  const loading = () => {
    return (
      <>
        <div className="loader-container">
          <div className="loader-container-inner">
            <div className="text-center">
              <Loader type="ball-pulse-rise" />
            </div>
            <h6 className="mt-5">Cargando MariloginsaTM...</h6>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <Suspense fallback={loading()}>
        <Switch>
          {/*Generales*/}
          <Route path='/passwordReset/:token' component={ResetPassword}/>
          <Route path="/pages" component={UserPages} />
          <Route path="/emailreset" component={EmailResetPassword} />
          <Route path="/" component={routes} />
          <Redirect from="*" to="/pages/login-boxed" />
        </Switch>
      </Suspense>

      <Route
        exact
        path="/"
        render={() => (
          <PrivateRoute path="/">
            <Redirect to="/dashboard/archive" />
          </PrivateRoute>
        )}
      />

      <ToastContainer />
    </>
  );
};

export default AppMain;
