export const SET_USER = 'APP_STATE/SET_USER';
export const SET_PERMISSIONS = 'APP_STATE/SET_PERMISSIONS';
export const SET_NAMEPAGE = 'APP_STATE/SET_NAMEPAGE';
export const SET_PREVPAGE = 'APP_STATE/SET_PREVPAGE';
export const SET_ISFILTER = 'APP_STATE/SET_ISFILTER';
export const SET_SEARCHADVANCE = 'APP_STATE/SET_SEARCHADVANCE';
export const SET_IDURL = 'APP_STATE/SET_IDURL';
export const SET_ITEMS = 'APP_STATE/SET_ITEMS';
export const SET_PUSHURL = 'APP_STATE/SET_PUSHURL';
export const SET_UPDATEDELETE = 'APP_STATE/SET_UPDATEDELETE';
export const SET_IDPROFILE = 'APP_STATE/SET_IDPROFILE';
export const SET_ORG = 'APP_STATE/SET_ORH';
export const SET_CHECKBOX = 'APP_STATE/SET_CHECKBOX';
export const SET_OBJFORMADDITIONALFIELD = 'APP_STATE/SET_OBJFORMADDITIONALFIELD';
export const SET_ALLCOUNTRIES = 'APP_STATE/SET_ALLCOUNTRIES';
export const SET_LISTCHECKBOX = 'APP_STATE/SET_LISTCHECKBOX';
export const SET_USERLOOKUP = 'APP_STATE/SET_USERLOOKUP';
export const SET_MODALCHAT = 'APP_STATE/SET_MODALCHAT';
export const SET_ALLLOCALPROFILE = 'APP_STATE/SET_ALLLOCALPROFILE';
export const SET_ADDITIONALVALUESHOWTAB = 'APP_STATE/SET_ADDITIONALVALUESHOWTAB';
export const SET_CONTRACTTYPE = 'APP_STATE/SET_CONTRACTTYPE';
export const SET_LOADING = 'APP_STATE/SET_LOADING';
export const SET_REFRESHNOTIFICATION = 'APP_STATE/SET_REFRESHNOTIFICATION';
export const SET_REFRESHLISTTO = 'APP_STATE/SET_REFRESHLISTTO';
export const SET_MODALTODO = 'APP_STATE/SET_MODALTODO';
export const SET_ALERTACTIVITYNOTIFICATION = 'APP_STATE/SET_ALERTACTIVITYNOTIFICATION';
export const SET_ALERTACTIVITYTASK = 'APP_STATE/SET_ALERTACTIVITYTASK';
export const SET_OBJFORMFILTERHEADER = 'APP_STATE/SET_OBJFORMFILTERHEADER';
export const SET_OBJFORMFILTERBUTTON = 'APP_STATE/SET_OBJFORMFILTERBUTTON';
export const SET_DATASHOW = 'APP_STATE/SET_DATASHOW';
export const SET_RESET_PAGINATOR = 'APP_STATE/SET_RESET_PAGINATOR';
export const SET_GETITEMS = 'APP_STATE/SET_GETITEMS';
export const SET_TOTALACTIVITIES = 'APP_STATE/SET_TOTALACTIVITIES';
export const SET_TOTALPERCENTAGE = 'APP_STATE/SET_TOTALPERCENTAGE';


export const setUser = user => ({
    type: SET_USER,
    user
});

export const setPermissions = permissions => ({
    type: SET_PERMISSIONS,
    permissions
});

export const setUsersLookup = usersLookup => ({
    type: SET_USERLOOKUP,
    usersLookup
});

export const setNamePage = nameP => ({
    type: SET_NAMEPAGE,
    nameP
});

export const setGetItems = getItems => ({
    type: SET_GETITEMS,
    getItems
});

export const setPrevPage = prevPage => ({
    type: SET_PREVPAGE,
    prevPage
});

export const setIsFilter = isFilter => ({
    type: SET_ISFILTER,
    isFilter
});

export const setSearchAdvance = searchAdvance => ({
    type: SET_SEARCHADVANCE,
    searchAdvance
});

export const setIdURL = idURL => ({
    type: SET_IDURL,
    idURL
});

export const setContractType = contractType => ({
    type: SET_CONTRACTTYPE,
    contractType
});

export const setIdProfile = idProfile => ({
    type: SET_IDPROFILE,
    idProfile
});

export const setItems = items => ({
    type: SET_ITEMS,
    items
});

export const setPushURL = pushURL => ({
    type: SET_PUSHURL,
    pushURL
});

export const setUpdateDelete = (updateDelete) => ({
    type: SET_UPDATEDELETE,
    updateDelete
});

export const setModalCHAT = (modalCHAT) => ({
    type: SET_MODALCHAT,
    modalCHAT
});

export const setModalToDo = (modalToDo) => ({
    type: SET_MODALTODO,
    modalToDo
});

export const setRefreshNotification = (refreshNotification) => ({
    type: SET_REFRESHNOTIFICATION,
    refreshNotification
});

export const setRefreshListTo = (refreshListTo) => ({
    type: SET_REFRESHLISTTO,
    refreshListTo
});

export const setOrg = (org) => ({
    type: SET_ORG,
    org
});

export const setCheckBoxSelected = (check) => ({
    type: SET_CHECKBOX,
    check
});

export const setListCheckMarkSelected = (listCheck) => ({
    type: SET_LISTCHECKBOX,
    listCheck
})

export const setObjFormAdditionalField = (objAdditionalField) => ({
    type: SET_OBJFORMADDITIONALFIELD,
    objAdditionalField
});

export const setAllCountries = (allCountries) => ({
    type: SET_ALLCOUNTRIES,
    allCountries
});

export const setAllLocalProfile = (allLocalProfile) => ({
    type: SET_ALLLOCALPROFILE,
    allLocalProfile
});

export const setAdditionalValueShowTab = (additionalValueShowTab) => ({
    type: SET_ADDITIONALVALUESHOWTAB,
    additionalValueShowTab
});

export const setLoadingInitial = (loadingInitial) => ({
    type: SET_LOADING,
    loadingInitial
});

export const setAlertActivityNotification = (alertActivityNotification) => ({
    type: SET_ALERTACTIVITYNOTIFICATION,
    alertActivityNotification
});

export const setAlertActivityTask = (alertActivityTask) => ({
    type: SET_ALERTACTIVITYTASK,
    alertActivityTask
});

export const setObjFormFilterHeader = (objFormFilterHeader) => ({
    type: SET_OBJFORMFILTERHEADER,
    objFormFilterHeader
});

export const setObjFormFilterButton = (objFormFilterButton) => ({
    type: SET_OBJFORMFILTERBUTTON,
    objFormFilterButton
});

export const setDataShow = (dataShow) => ({
    type: SET_DATASHOW,
    dataShow
});

export const setResetPaginator = (resetPaginator) => ({
    type: SET_RESET_PAGINATOR,
    resetPaginator
});

export const setTotalActivities = (totals) => ({
    type: SET_TOTALACTIVITIES,
    totals
});

export const setTotalPercentage = (totalPercentage) => ({
    type: SET_TOTALPERCENTAGE,
    totalPercentage
});

export default function reducer(state = {
    user: '',
    permissions: [],
    nameP: [],
    prevPage: '',
    isFilter: {'isFilter' : false, 'isAlertFilter': false},
    searchAdvance: {},
    idURL:0,
    items: [],
    pushURL: {'id':'', 'domain':''},
    updateDelete: {'id': 0, 'type':''},
    idProfile: 0,
    org: '',
    check: false,
    objAdditionalField: {},
    allCountries: [],
    listCheck: [],
    usersLookup: [],
    modalCHAT: {'typeAction': '', 'modal': false, 'id': 0},
    allLocalProfile: null,
    additionalValueShowTab: {},
    contractType: '',
    loadingInitial: false,
    refreshNotification: '',
    refreshListTo: false,
    modalToDo: {'items': {}, 'modal': false},
    alertActivityNotification: false,
    alertActivityTask: false,
    objFormFilterHeader: {},
    objFormFilterButton: {},
    resetPaginator: false,
    getItems: {},
    totals: [{toDoTotal: 0},
        {commentTotal: 0},
        {messageTotal: 0},
        {toDoStatus: 0}],
    totalPercentage:{}
}, action) {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.user
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.permissions
            };
        case SET_NAMEPAGE:
            return {
                ...state,
                nameP: action.nameP
            };
        case SET_PREVPAGE:
            return {
                ...state,
                prevPage: action.prevPage
            };
        case SET_ISFILTER:
            return {
                ...state,
                isFilter: action.isFilter
            };
        case SET_SEARCHADVANCE:
            return {
                ...state,
                searchAdvance: action.searchAdvance
            };
        case SET_IDURL:
            return {
                ...state,
                idURL: action.idURL
            };
        case SET_ITEMS:
            return {
                ...state,
                items: action.items
            };
        case SET_PUSHURL:
            return {
                ...state,
                pushURL: action.pushURL
            };
        case SET_UPDATEDELETE:
            return {
                ...state,
                updateDelete: action.updateDelete
            };
        case SET_IDPROFILE:
            return {
                ...state,
                idProfile: action.idProfile
            };
        case SET_ORG:
            return {
                ...state,
                org: action.org
            };
        case SET_CHECKBOX:
            return {
                ...state,
                check: action.check
            };
        case SET_OBJFORMADDITIONALFIELD:
            return {
                ...state,
                objAdditionalField: action.objAdditionalField
            };
        case SET_ALLCOUNTRIES:
            return {
                ...state,
                allCountries: action.allCountries
            };
        case SET_LISTCHECKBOX:
            return {
                ...state,
                listCheckBox: action.listCheck
            };
        case SET_USERLOOKUP:
            return {
                ...state,
                usersLookup: action.usersLookup
            };
        case SET_MODALCHAT:
            return {
                ...state,
                modalCHAT: action.modalCHAT
            };
        case SET_ALLLOCALPROFILE:
            return {
                ...state,
                allLocalProfile: action.allLocalProfile
            };
        case SET_ADDITIONALVALUESHOWTAB:
            return {
                ...state,
                additionalValueShowTab: action.additionalValueShowTab
            };
        case SET_CONTRACTTYPE:
            return {
                ...state,
                contractType: action.contractType
            };
        case SET_LOADING:
            return {
                ...state,
                loadingInitial: action.loadingInitial
            };
        case SET_REFRESHNOTIFICATION:
            return {
                ...state,
                refreshNotification: action.refreshNotification
            };
        case SET_REFRESHLISTTO:
            return {
                ...state,
                refreshListTo: action.refreshListTo
            };
        case SET_MODALTODO:
            return {
                ...state,
                modalToDo: action.modalToDo
            };
        case SET_ALERTACTIVITYNOTIFICATION:
            return {
                ...state,
                alertActivityNotification: action.alertActivityNotification
            };
        case SET_ALERTACTIVITYTASK:
            return {
                ...state,
                alertActivityTask: action.alertActivityTask
            };
        case SET_OBJFORMFILTERHEADER:
            return {
                ...state,
                objFormFilterHeader: action.objFormFilterHeader
            };
        case SET_OBJFORMFILTERBUTTON:
            return {
                ...state,
                objFormFilterButton: action.objFormFilterButton
            };
        case SET_DATASHOW:
            return {
                ...state,
                dataShow: action.dataShow
            };
        case SET_RESET_PAGINATOR:
            return {
                ...state,
                resetPaginator: action.resetPaginator
            };
        case SET_GETITEMS:
            return {
                ...state,
                getItems: action.getItems
            };
        case SET_TOTALACTIVITIES:
            return {
                ...state,
                totals: action.totals
            };
        case SET_TOTALPERCENTAGE:
            return {
                ...state,
                totalPercentage: action.totalPercentage
            };
        default:
            return state;
    }
}
